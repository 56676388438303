import React from 'react';
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import "./Hero.scss"
import { Link } from "gatsby"

function Hero(props) {
  const { type } = props;
  const jsx = {
      front: (
          <div className='mo-hero'>
              <div className='mo-hero__slide'>
                  <div className='mo-hero__content mo-grid'>
                      <StaticImage src="../images/monday_logo-black.svg" placeholder= "none" className='mo-hero__logo' alt='Hero' />
                      <h1
                          className='mo-hero__title mo-hero__title--fontbody mo-h2--fontbody mo-h2'
                      >
                          {'Weeks have never started this well'}<span className="mo-animated-dot">.</span>
                      </h1>
                  </div>
              </div>
          </div>
      ),
      other: (
          <div className='mo-hero mo-hero--narrow'>
              <div className='mo-hero__slide'>
                  <div className='mo-hero__content mo-grid'>
                      <Link href='/'><StaticImage placeholder= "none" src="../images/monday_logo-black.svg" className='mo-hero__logo' alt='Monday Digital Logo' /><span className='mo-hero__logo-title'>Back to Home</span></Link>
                  </div>
              </div>
          </div>
      )
  };

  return jsx[type];
}

Hero.PropTypes = {
  type: PropTypes.string.isRequired
};

Hero.defaultProps = {
  type: 'other'
};

export default Hero;