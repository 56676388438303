import * as React from "react"
import Hero from './Hero';
import WhoWeAre from './WhoWeAre';
import Testimonial from './Testimonial';
import Footer from './Footer';

const FrontPage = () => 
<>
  <Hero type={'front'} />
  <WhoWeAre />
  <Testimonial />
  <Footer />
</>

export default FrontPage
