import React from 'react';

export default function ClientItem({data}) {
  return (
  <div className='mo-clients__item'>
    <a href={data.link} target='_blank' rel="noreferrer">
      <img className='mo-clients__img' src={data.img} alt={data.name} />
    </a>
  </div>
  )
}