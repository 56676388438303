import * as React from "react"
import FrontPage from '../components/FrontPage';
import Seo from '../components/Seo';

import "../scss/main.scss"

// markup
const IndexPage = () => {
  return (
    <main>
      <Seo />
      <FrontPage />
    </main>
  )
}

export default IndexPage
