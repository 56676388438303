import React from 'react';
import _ from 'lodash';
import { config } from '../config';
var classNames = require('classnames');

export default function SomeBlock() {
    const someLinks = config.companyInfo.someLinks;

    return (
        <div className='mo-colorwrap mo-colorwrap--white mo-colorwrap--white--border mo-colorwrap--nopadding'>
            <div className='mo-grid'>
                <div className='mo-footer'>
                    <div className='mo-footer__some'>
                        <ul className='mo-footer__some__ul'>
                            {
                                _.map(someLinks, (d, i) => {
                                    const someItemClasses = classNames("mo-footer__some__item", {
                                        "mo-footer__some__item--border": i !== (someLinks.length - 1)
                                    })
                                    return (
                                        <li key={i} className='mo-footer__some__li'>
                                            <a
                                                href={d.link}
                                                className={someItemClasses}
                                                target='_blank'
                                                rel="noreferrer"
                                            >
                                                <img src={d.icon} className="mo-icon mo-icon--footer" alt="d.displayName" />
                                                <span className='mo-footer__some__text'>{d.displayName}</span>
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
