import React from 'react';
import { config } from '../config';
import heart from "../images/icons/heart.svg"

export default function FooterLove() {
    return (
        <div className='mo-colorwrap mo-colorwrap--white mo-colorwrap--smallpadding v1'>
            <div className='mo-grid'>
                <p className='mo-textaligncenter'>
                    Made with 
                    <img src={heart} className="mo-icon" alt="Heart" />
                     in {config.companyInfo.city}
                </p>
            </div>
        </div>
    );
}
