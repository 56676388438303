import remedyLogo from "./images/remedy.png"
import elisaLogo from "./images/elisa.png"
import adidasLogo from "./images/adidas.png"
import ideanLogo from "./images/idean.png"
import valioLogo from "./images/valio_white.png"
import palmuLogo from "./images/palmu.png"
import veikkausLogo from "./images/veikkaus.png"
import reaktorLogo from "./images/reaktor.png"
import valveLogo from "./images/valve.png"
import nordkappLogo from "./images/nordkapp.png"
import hmdLogo from "./images/hmd.png"
import sc5Logo from "./images/sc5.png"
import facebook from "./images/icons/facebook.svg"
import linkedin from "./images/icons/linkedin.svg"
import twitter from "./images/icons/twitter.svg"

export const config = {
  "staticUrl": "https://localhost:8050",
  "companyInfo": {
      "officialName": "Monday Digital Oy",
      "businessId": "2435962-4",
      "streetAddress": "",
      "postalCode": "",
      "city": "Helsinki",
      "contactEmail": "info@monday.fi",
      "someLinks": [
          {
              "name": "facebook",
              "link": "https://www.facebook.com/mondaydigital",
              "displayName": "Facebook",
              "icon": facebook
          },
          {
              "name": "twitter",
              "link": "https://twitter.com/mondayfi",
              "displayName": "Twitter",
              "icon": twitter
          },
          {
              "name": "facebook",
              "link": "https://www.linkedin.com/company/monday-digital-oy",
              "displayName": "LinkedIn",
              "icon": linkedin
          }
      ]
  },
  "personnelInfo": {
      "samuel": {
          "imagePath": "/static/images/samuel.jpg",
          "firstName": "Samuel",
          "lastName": "Klinkmann",
          "email": "samuel@monday.fi",
          "linkedinLink": "https://fi.linkedin.com/in/samuelklinkmann",
          "phone": {
              "display": "040 486 92 09",
              "link": "+358404869209"
          },
          "title": "UX Developer"
      },
  },
  "clientList": [
    {
      "name": "Adidas",
      "img": adidasLogo,
      "link": "https://www.adidas.com/"
    },
    {
      "name": "Hmd Global (Nokia Phones)",
      "img": hmdLogo,
      "link": "https://www.nokiaphones.com/"
    },
    {
      "name": "Remedy Entertainment",
      "img": remedyLogo,
      "link": "https://www.remedygames.com/"
    },
    {
      "name": "Elisa Oyj",
      "img": elisaLogo,
      "link": "https://www.elisa.fi/"
    },
    {
      "name": "Valio Oy",
      "img": valioLogo,
      "link": "https://www.valio.fi/"
    },
    {
      "name": "Veikkaus Oy",
      "img": veikkausLogo,
      "link": "https://www.veikkaus.fi/"
    },
    {
      "name": "Reaktor Oy",
      "img": reaktorLogo,
      "link": "https://www.reaktor.fi/"
    },
    {
      "name": "Idean Oy",
      "img": ideanLogo,
      "link": "https://www.idean.fi/"
    },
    {
      "name": "Palmu Oy",
      "img": palmuLogo,
      "link": "https://www.palmu.fi/"
    },
    {
      "name": "Valve Oy",
      "img": valveLogo,
      "link": "https://www.valve.fi/"
    },
    {
      "name": "Nordkapp Oy",
      "img": nordkappLogo,
      "link": "https://www.nordkapp.fi/"
    },
    {
      "name": "SC5 Online",
      "img": sc5Logo,
      "link": "https://www.linkedin.com/company/sc5/"
    }
  ]
};
