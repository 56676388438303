import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { config } from '../config';
import email from "../images/icons/email.svg"
import linkedin from "../images/icons/linkedin.svg"

export default function WhoWeAre() {
    const info = config.personnelInfo.samuel;

    return (
        <div className='mo-colorwrap mo-colorwrap--white mo-colorwrap--white--border'>
            <div className='mo-grid'>
                <div className='mo-stand-alone'>
                    <div className='mo-authorimage mo-authorimage--stand-alone'>
                        <StaticImage
                            className='mo-authorimage__image'
                            src="../images/samuel.jpg"
                            alt={`${info.firstName} ${info.lastName}`}
                        />
                    </div>
                    <div className='mo_linkicon  mo_linkicon--black'>
                        <span className='mo_linkicon__link mo_linkicon__link--no-border'><strong>Samuel Klinkmann</strong><br />
                    </span>
                    </div>
                    <a href={`mailto:${info.email}`} className='mo_linkicon  mo_linkicon--black'>
                        <img src={email} className="mo-icon mo-icon--no-left-margin" alt="Email" />
                        <span className='mo_linkicon__link'>{info.email}</span>
                    </a>
                    <br />
                    <a href={info.linkedinLink} className='mo_linkicon mo_linkicon--black'>
                        <img src={linkedin} className="mo-icon mo-icon--no-left-margin" alt="LinkedIn" />
                        <span className='mo_linkicon__link'>{'LinkedIn'}</span>
                    </a>
                </div>
                <h2 className='mo-stand-alone__title'>Tjena!</h2>
                    <p>I'm Samuel, a developer with a passion for creating products with extraordinary UX.</p>
                    <p>You could call me a front-end/UI/web developer, but I have a background in design, meaning I'm quite the multitool when it comes to creating digital products.</p>
                    <p>I'm quite fond of React, but I've also worked extensively with Angular, Backbone and other JS frameworks. Design&nbsp;systems are something I've been pushing early on.<br />I've worked with AB-testing & web analytics, but also had the pleasure to create game UI's.</p>
                    <p>Currently I’m working at <a href="https://supercell.com" className="mo_linkicon__link" rel="noreferrer" target="_blanK">Supercell</a> as a Technical producer / Community tech lead.</p>
                    <p><strong>I’m not looking for new job opportunities at the moment.</strong></p>
            </div>
        </div>
    );
}
